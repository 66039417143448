:root {
  /* these values get overwritten when we get a response from the branding endpoint */
  --receiving_bg_primary: #ffffff;
  --receiving_bg_secondary: #edf3fc;
  --receiving_bg_tertiary: #1d74ec;
  --receiving_text: #282f38;
  --logo_bg: #edf3fc;
  --logo_height: 0px;
  --logo_width: 0px;
  --logo_vertical_margin: 0px;
  --receiving_logo_url: "";
}

body {
  margin: 25px 20px;
  background-color: var(--receiving_bg_primary);
  color: var(--receiving_text);
  font-family: Wix Madefor Display;
  font-size: 14px;
  text-wrap: balance;
  text-align: center;
  line-height: 1.7;
}

nav {
  background-color: var(--logo_bg);
  padding-top: var(--logo_vertical_margin);
  padding-bottom: var(--logo_vertical_margin);
  margin: -25px -20px 40px -20px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 12px 12px;
}

button {
  height: 52px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--receiving_bg_primary);
  color: var(--receiving_text);
  border: solid;
  border-width: 1px;
  border-color: var(--receiving_bg_tertiary);
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 12px;
}

.button_with_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.icon_border {
  border: solid;
  border-color: var(--receiving_text);
  border-radius: 16px;
  border-width: 1px;
  height: 28px;
}

button:disabled,
button[disabled] {
  background-color: var(--receiving_bg_secondary);
}

label {
  float: left;
}

input {
  color: var(--receiving_text);
  background-color: var(--receiving_bg_primary);
  height: 52px;
  width: calc(100vw - 70px);
  padding: 1px 15px;
  margin-bottom: 20px;
  border: solid;
  border-color: var(--receiving_bg_tertiary);
  border-radius: 12px;
  border-width: 1px;
  font-family: Wix Madefor Display;
  font-size: 14px;
}

input:focus {
  outline: none;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

h5 {
  font-weight: 400;
  margin: 15px 0 40px;
  font-size: 14px;
}

b {
  font-weight: 550;
}

a {
  height: 32px;
  border-radius: 8px;
  width: fit-content;
  padding: 0 20px;
  background-color: var(--receiving_bg_tertiary);
  color: var(--receiving_bg_primary);
  font-size: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.advertising_box {
  background-color: var(--receiving_bg_secondary);
  border-radius: 12px;
  padding: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}

.logo {
  height: var(--logo_height);
  width: var(--logo_width);
  background-image: var(--receiving_logo_url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.center_page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 85vh;
}
